import {get} from '../../utils/request'
/**
 * 微官网查询
 */
export function findWebsiteApp(){
    
    return get(
        `/website/findWebsiteApp`,
    )
}/**
 * 微官网地址
 */
export function getWebsiteDetailsUrl(websiteId){

    return get(
        `/website/getWebsiteDetailsUrl/2/${websiteId}`,
    )
}

