<template>
  <div class="panoramic-containei" v-if="!showModel">
    <div class="comback-box" @click="handleComback">返回</div>
    <iframe
      :src="jumpUrl"
      scrolling="yes"
      frameborder="0"
      style="height: 100vh; width: 100%"
    >
    </iframe>
  </div>
  <div class="microWebsite-container" v-if="showModel">
    <header class="header">
      <van-nav-bar
        title="微官网"
        right-text=""
        @click-right="handleShareWebsite"
        safe-area-inset-top
      >
      </van-nav-bar>
    </header>
    <article class="article">
      <van-sticky>
        <div class="card-box-cls">
          <div class="card-box">
            <div class="card-info" @click="handleViewUserCard">
              <div class="card-left">
                <img :src="userHeadImg" alt="" />
                <div class="creater-info">
                  <div class="title">{{ userCard.name }}</div>
                  <div class="second">
                    {{ userCard.merchantName }}
                  </div>
                </div>
              </div>
              <div
                class="card-right"
                @click.stop="handleCall(userCard.contactNum)"
              >
                <div class="phone">电话联系</div>
              </div>
            </div>
            <div class="synopsis">
              <span>{{ isFold ? foldSynopsisStr : synopsisStr }}</span>
              <span
                style="color: #999"
                @click="handleIsFold"
                v-if="synopsisStr.length > 40"
                >{{ isFold ? "...展开" : "&lt;&lt;折叠" }}</span
              >
            </div>
          </div>
        </div>
      </van-sticky>
      <div class="webesite-swip">
        <van-swipe class="my-swipe" :autoplay="3000">
          <van-swipe-item
            v-for="item in website.bannerImgVOList"
            :key="item"
            @click="loadSwiperWebsite(item.jumpUrl)"
          >
            <img :src="imgUrl + '/' + item.bannerImgUrl" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div class="company-profile">
        <div>公司简介</div>
        <span>{{ website.merchantProfile }}</span>
      </div>
      <div class="website-content">
        <div class="website-content-box">
          <transform-html :contents="website.content"></transform-html>
        </div>
      </div>
      <div class="contact-us">联系我们</div>
      <div class="company-info">
        <img
          :src="website.logoUrl ? `${imgUrl}/${website.logoUrl}` : defaultLogo"
          alt=""
        />
        <div class="company-info-describe">
          <p>{{ website.merchantName }}</p>
          <p>
            公司地址：{{
              website.merchantAreaName
                ? website.merchantAreaName + website.address
                : ""
            }}
          </p>
        </div>
      </div>
      <div class="company-phone">
        <p>联系电话</p>
        <p>
          {{ website.phoneNum }}&nbsp;&nbsp;&nbsp;&nbsp;<icon-svg
            iconClass="icondianhualianxi"
            @click.stop="handleCall(website.phoneNum)"
          />
        </p>
      </div>
      <div class="company-QRcode">
        <p>二维码</p>
        <div class="QRcode-img">
          <img :src="QRcodeUrl" alt="" />
        </div>
      </div>
      <div class="need-consult">
        <p>我要咨询</p>
        <van-form ref="addInfoForm" error-message-align="right">
          <div class="info">
            <van-field
              v-model="addInfo.username"
              label="姓名"
              placeholder="请输入您的姓名"
              clearable
              :rules="[{ required: true, message: '名字为必填项' }]"
            />
            <van-field
              v-model="addInfo.phone"
              label="手机号"
              type="tel"
              placeholder="请输入您的手机号"
              clearable
              :rules="[
                {
                  required: true,
                  pattern: /^1[3|4|5|7|8]\d{9}$/,
                  message: '请输入正确的手机号',
                },
              ]"
            />
            <van-field
              v-model="addInfo.content"
              clearable
              label="留言"
              type="textarea"
              placeholder="请输入留言"
              :rules="[{ required: true, message: '请输入留言' }]"
            />
            <van-button
              square
              type="primary"
              color="#289FFF"
              @click="handleAddInfo"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
    </article>
    <!-- 分享框 -->
    <div class="share_box">
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="options"
        cancel-text="取消"
        @select="onSelect"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted, onUnmounted } from "vue";
import transformHtml from "../../components/transformHTML.vue";
import { useRoute, useRouter } from "vue-router";
import { findWebsiteApp } from "../../api/microWebsite";
import { createConsult } from "@/api/user/userCard";
import { Dialog, Toast } from "vant";
import { useWxAuthorization } from "@/hooks/wxAuthorization";
import { addClue, addClueBehaviorInfo } from "@/api/user/clue";
import { initWechatShare } from "@/hooks/initWechatShare";
import $ from "jquery";

export default {
  components: {
    transformHtml,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    let addInfoForm = ref(null);
    let internal = null;
    let seconds = 0;
    const merchantId = route.params.merchantId;
    const recommendClueId = route.params.recommendClueId?route.params.recommendClueId:''
    const state = reactive({
      jumpUrl: "",
      showModel: true,
      openId: null,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      defaultLogo: require("../../assets/images/company-logo-default.png"),
      morenHeadImg: require("../../assets/icons/Vector.png"),
      isFold: true,
      foldSynopsisStr: "",
      synopsisStr: "",
      website: [],
      showShare: false,
      QRcodeUrl: "",
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
        { name: "复制链接", icon: require("@/assets/icons/copy-link.png") },
      ],
      addInfo: {
        username: "",
        phone: "",
        content: "",
        busiId: "",
      },
      userCard: {},
      userHeadImg: "",
      consultId: "",
      clueId: "",
    });
    const loadWebsite = async () => {
      try {
        Toast.loading({
          forbidClick: true,
          message: "加载中...",
        });
        let res = await findWebsiteApp();
        if (res) {
          state.website = res;
          state.userCard = res.userCardVO;
          state.userHeadImg = res.userCardVO.headImgUrl
            ? state.imgUrl + "/" + res.userCardVO.headImgUrl
            : state.morenHeadImg;
          if (res.isQrCode === 1) {
            state.QRcodeUrl = `${state.imgUrl}/${res.qrCodeUrl}`;
          } else {
            state.QRcodeUrl = res.userCardVO.qrCode;
          }
          state.synopsisStr = res.userCardVO.selfIntro;
          state.foldSynopsisStr = res.userCardVO.selfIntro.substring(0, 44);

          state.synopsisStr = res.userCardVO.selfIntro
            ? res.userCardVO.selfIntro
            : "我坚信细节决定品质，专业决定价值！如有需求，请随时与我联系！";
          state.foldSynopsisStr = state.synopsisStr.substring(0, 44);
          state.addInfo.busiId = res.websiteId;
          console.log("微官网c端：", res);
        }
      } catch (err) {
        alert(err);
      } finally {
        Toast.clear();
      }
    };

    //跳转名片
    const handleViewUserCard = () => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      router.push({
        path: `/wxShareUserCard/${route.params.clueShareSource}/${merchantId}/${recommendClueId}`,
        query: { miniProgramPhone: miniProgramPhone, openId: state.openId },
      });
    };
    //点击轮播图
    const loadSwiperWebsite = (it) => {
      console.log("轮播图信息：", it);
      const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/;
      let iSweb = reg.test(it);
      if (iSweb) {
        state.jumpUrl = it.indexOf("https") === -1
              ? `https://${it}`
              : it;
        state.showModel = !state.showModel;
      }
    };

    //咨询
    const handleAddInfo = () => {
      let param = {
        ...state.addInfo,
      };
      addInfoForm.value.validate().then(() => {
        createConsult(param).then((data) => {
          if (data) {
            state.consultId = data.id;
            state.addInfo.username = "";
            state.addInfo.phone = "";
            state.addInfo.content = "";
            Toast.success("您的咨询已创建");
          }
        });
        countUserAction({
          openId: state.openId,
          clueId: state.clueId,
          type: 5,
        });
      });
    };
    const useWxAuth = async () => {
      try {
        let data = await useWxAuthorization();

        if (data) {
          state.openId = data.openId;
        } else {
          state.openId = route.query.openId;
        }

        if (state.openId) {
          await loadWebsite();
          await handleFirstAddClue();
          let temp = location.href.split("?")[0];
          if(recommendClueId){
            temp =temp.replace(recommendClueId,state.clueId)
          }else {
            temp = `${temp}/${state.clueId}`
          }
          let title = "微官网";
          // eslint-disable-next-line no-undef
          wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
              state.showSavePhone = true;
              // eslint-disable-next-line no-undef
              wx.miniProgram.postMessage({
                data: {
                  title: title,
                  img: "https://wca.ujiaku.com/upload/wx/wx_delivery_info.png",
                  openId: state.openId,
                  clueId: state.clueId,
                  type: 4,
                  shareUrl:temp
                },
              });
            } else {
              let title = "微官网";
              let desc = state.website.merchantProfile;
              let appPhone;
              if (localStorage.getItem("appPhone")) {
                appPhone = localStorage.getItem("appPhone")
              } else {
                appPhone = route.query.appPhone
              }
              let url = `${
                process.env.VUE_APP_WECHAT_SHARE
              }/shareForward?shareUrl=${temp}?appPhone=${appPhone}`;
              let imgUrl =
                "https://wca.ujiaku.com/upload/wx/wx_delivery_info.png";
              initWechatShare({
                title,
                desc,
                url,
                imgUrl,
                openId: state.openId,
                clueId: state.clueId,
                type: 4,
              });
            }
          });
          countUserAction({
            openId: state.openId,
            clueId: state.clueId,
            type: 1,
          });
        }
      } catch (e) {
        alert(e);
      }
    };
    // //获取openId
    // useWxAuthorization().then((data) => {
    //   if (data) {
    //     state.openId = data.openId;
    //   } else {
    //     state.openId = route.query.openId;
    //   }
    // });
    const countUserAction = async ({ clueId, openId, type }) => {
      /**
       * type:
       *  1查看
       *  2拨打
       *  3保存
       *  4转发
       *  5提交咨询
       *  6点赞
       *  7收藏
       *  8留言
       */
      if (clueId) {
        await addClueBehaviorInfo({ clueId, openId, type });
      }
    };
    //添加线索
    const handleAddClue = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
        let param = {
          shareId: state.website.websiteId,
          clueSource: 6,
          browseSecond: seconds,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          name: "微官网",
          createId: state.website.loginUserId,
          consultId: state.consultId,
          clueShareSource: route.params.clueShareSource,
          clueId: state.clueId,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        $.ajax({
          async: false,
          url: `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          type: "post",
          contentType: "application/json;charset=UTF-8",
          data: JSON.stringify(param),
        });
      }
      seconds = 0;
      clearInterval(internal);
    };
    const handleWindowHide = () => {
      if (seconds > 0) {
        let miniProgramPhone = route.query.state
          ? route.query.state
          : route.query.miniProgramPhone;
        let param = {
          shareId: state.website.websiteId,
          clueSource: 6,
          browseSecond: seconds,
          beSharePhone: miniProgramPhone,
          openId: state.openId,
          name: "微官网",
          createId: state.website.loginUserId,
          consultId: state.consultId,
          clueShareSource: route.params.clueShareSource,
          clueId: state.clueId,
          recommend_Clue_Id:recommendClueId,
          merchantId
        };
        let blob = new Blob([JSON.stringify(param)], {
          type: "application/json",
        });

        navigator.sendBeacon(
          `${process.env.VUE_APP_BASE_API}/user/clue/addClue`,
          blob
        );
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleVisibilityStateChange = () => {
      if (document.visibilityState === "hidden") {
        handleAddClue();
        seconds = 0;
        clearInterval(internal);
      }
    };
    const handleFirstAddClue = async () => {
      let miniProgramPhone = route.query.state
        ? route.query.state
        : route.query.miniProgramPhone;
      let param = {
        shareId: state.website.websiteId,
        clueSource: 6,
        browseSecond: 0,
        beSharePhone: miniProgramPhone,
        openId: state.openId,
        name: "微官网",
        createId: state.website.loginUserId,
        consultId: state.consultId,
        clueShareSource: route.params.clueShareSource,
        recommend_Clue_Id:recommendClueId,
        merchantId
      };
      let clueId = await addClue(param);
      state.clueId = clueId;
      return clueId;
    };
    onMounted(() => {
      internal = setInterval(() => {
        seconds++;
      }, 1000);
      document.addEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.addEventListener("pagehide", handleWindowHide, false);
    });
    onUnmounted(() => {
      clearInterval(internal);
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityStateChange
      );
      window.removeEventListener("pagehide", handleWindowHide, false);
      handleAddClue();
    });
    //电话
    const handleCall = (num) => {
      if (num) {
        countUserAction({
          openId: state.openId,
          clueId: state.clueId,
          type: 2,
        });
        let a = document.createElement("a");
        a.setAttribute("href", `tel:${num}`);
        a.click();
      } else {
        Dialog.alert({ title: "错误", message: "没有电话号码" });
      }
    };
    //展开收起
    const handleIsFold = () => {
      state.isFold = !state.isFold;
    };
    const handleBlack = () => {
      router.back();
    };
    const handleShareWebsite = () => {
      state.showShare = true;
    };

    const onSelect = (index) => {
      console.log("分享到哪里：", index);
    };

    const handleComback = () => {
      state.jumpUrl = ''
      state.showModel = !state.showModel;
    };

    useWxAuth();

    return {
      ...toRefs(state),
      handleComback,
      handleViewUserCard,
      handleAddInfo,
      handleIsFold,
      onSelect,
      handleBlack,
      handleShareWebsite,
      handleCall,
      addInfoForm,
      loadSwiperWebsite,
    };
  },
};
</script>

<style lang="scss">
.panoramic-containei {
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  .comback-box {
    position: absolute;
    height: 36px;
    width: 60px;
    line-height: 36px;
    text-align: center;
    left: 0;
    top: 100px;
    color: white;
    font-size: 14px;
    background: rgba(46, 46, 46, 0.5);
    border-radius: 0 20px 20px 0;
    opacity: 0.5;
  }
}
.microWebsite-container {
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .van-nav-bar__text {
      color: #ff6e01;
    }
  }

  .need-consult {
    .info {
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .van-field {
        width: 80%;
      }
      .van-cell::after {
        border: none;
      }
      .van-field__control {
        border: 1px solid #6666;
        border-radius: 4px;
        //   width: 160px;
      }
      .van-field__label {
        color: black;
        width: 50px;
      }

      [class*="van-hairline"]::after {
        border-bottom: none;
      }
      .van-button--square {
        width: 160px;
        height: 44px;
        border-radius: 5px;
      }
    }
  }
  .website-footer {
    .van-tabbar-item__text {
      display: flex;
      flex-direction: column;
      align-items: center;
      .zixun {
        display: block;
        margin-bottom: 4px;
        font-size: 22px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.microWebsite-container {
  font-size: 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  .article {
    flex: 1;
    overflow-x: hidden;
    background: #e5e5e5;
    .card-box-cls {
      padding: 5px 16px 16px 16px;
    }

    .card-box {
      // margin: 5px 16px 16px 16px;
      padding: 12px;
      border-radius: 8px;
      text-align: left;
      background-color: #fff;
      box-shadow: 0px 0px 15px rgb(229, 229, 230);

      .synopsis {
        margin-top: 5px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.84);
      }

      .card-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .card-left {
          display: flex;

          img {
            height: 40px;
            width: 40px;
            border-radius: 20px;
          }

          .creater-info {
            margin-left: 8px;

            .title {
              font-weight: bold;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.84);
              margin-bottom: 3px;
            }

            .second {
              font-weight: normal;
              font-size: 11px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }

        .card-right {
          .phone {
            width: 80px;
            height: 30px;
            background-color: #289fff;
            font-size: 12px;
            color: white;
            text-align: center;
            line-height: 30px;
            border-radius: 5px;
          }
        }
      }
    }
    .webesite-swip {
      height: 160px;
      padding: 0 16px;
      .my-swipe {
        border-radius: 4px;
        height: 100%;
        width: 100%;

        box-shadow: 1px 2px 8px 4px #6666;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .cover-img {
      height: 160px;
      padding: 0 16px;
      > img {
        display: block;
        border-radius: 4px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 1px 4px 8px 4px #6666;
      }
    }
    .company-profile {
      text-align: left;
      background-color: #fff;
      padding: 12px 16px;
      margin: 5px 0;
      :nth-child(1) {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 9px;
      }
      > span {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
    }
    .website-content {
      width: 100%;
      padding: 5px 16px;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      background-color: #ffffff;
      .website-content-box {
        img {
          width: 100%;
        }
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
    .contact-us {
      padding: 8px 16px;
      font-weight: 600;
      font-size: 14px;
    }
    .company-info {
      // height: 41px;
      background-color: #fff;
      padding: 16px;
      display: flex;
      > img {
        height: 40px;
        width: 40px;
        border-radius: 20px;
      }
      .company-info-describe {
        flex: 1;
        margin-left: 8px;
        > p {
          margin: 0;
          padding: 0;
        }
        > :nth-child(1) {
          font-weight: 600;
          font-size: 14px;
        }
        > :nth-child(2) {
          font-weight: 400;
          font-size: 11px;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    .company-phone {
      background-color: #fff;
      padding: 16px;
      margin: 5px 0;
      > p {
        margin: 0;
        padding: 0;
      }
      > :nth-child(1) {
        font-weight: 400;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.4);
      }
      > :nth-child(2) {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
      }
    }
    .company-QRcode {
      height: 266px;
      background-color: #fff;
      padding: 16px;
      > p {
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 14px;
      }
      .QRcode-img {
        text-align: center;
        img {
          height: 208px;
          width: 208px;
        }
      }
    }

    .need-consult {
      //   height: 287px;
      padding: 8px 16px;
      margin: 5px 0;
      background-color: #fff;
      > p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        padding: 0;
      }
    }
  }

  .website-footer {
    position: relative;
    height: 50px;
    .add_article {
      height: 36px;
      width: 52px;
      color: #fff;
      line-height: 36px;
      border-radius: 8px;
      font-size: 28px;
      position: fixed;
      z-index: 2;
      left: 50%;
      bottom: 7px;
      transform: translateX(-50%);
      background-color: #ff6e01;
    }
  }
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
}
</style>
